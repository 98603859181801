import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';

import DefaultLayout from '../components/layout';
import SEO from '../components/seo';
import config from '../../data/SiteConfig';

const TagsPage = ({ data }) => {
  const { allMarkdownRemark } = data;

  const mapping = {};

  allMarkdownRemark.edges.forEach(({ node }) => {
    const { tags } = node.frontmatter;
    tags.forEach((name) => {
      if (mapping[name]) {
        mapping[name] += 1;
      } else {
        mapping[name] = 1;
      }
    });
  });

  const tags = Array.from(Object.keys(mapping)).sort(
    (b, a) => mapping[a] - mapping[b],
  );

  return (
    <DefaultLayout>
        <SEO title="Tags" description={config.siteTitle} keywords={[`gobinda`, `tags`, `blogs`, `thakur`]} />
        <div className="pages">
            <h3>Tags</h3>
            <div className="page-tag">
                {tags.map(tag => (
                    <Link className="original-tag" key={tag} to={`/tags/${_.kebabCase(tag)}`}>
                        {tag} &nbsp; <span>{mapping[tag]}</span>
                    </Link>
                ))}
            </div>
      </div>
    </DefaultLayout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query getAllTags {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`;